<template>
    <el-dialog :close-on-click-modal="false" title="角色权限分配" :visible.sync="show" width="500px" class="selfInputBox">


        <div class="boxThree">

            <el-tree :data="data" show-checkbox default-expand-all node-key="id" ref="tree" highlight-current
                :props="defaultProps">
            </el-tree>

        </div>


        <span slot="footer">
            <div class="buttons">
                <el-button type="primary" @click="refresh">保存</el-button>
                <!-- <el-button @click="$refs.form.resetFields()">重置</el-button> -->
            </div>
        </span>

    </el-dialog>
</template>

<script>
    export default {

        data() {
            return {
                show: false,
                formShow: false,

                data: [],

                defaultProps: {
                    children: 'children',
                    label: 'name'
                }

            }
        },
        methods: {

            open() {

                this.getList()
            },
            refresh() {
                this.show = false
                this.$emit('refresh')
                setTimeout(() => {
                    this.formShow = false
                }, 1000)
            },


            getList() {

                this.$http.get('/api/menu/tree').then((res) => {

                    if (res.code == 200) {

                        this.data = res.data

                        this.formShow = true
                        this.show = true

                    }

                    if (res.code != 200) { //请求错误
                        this.$message.error(res.msg);

                    }
                }).catch((err) => {
                    console.log(err)
                });
            },

            // getCheckedNodes() {
            //   console.log(this.$refs.tree.getCheckedNodes());
            // },
            // getCheckedKeys() {
            //   console.log(this.$refs.tree.getCheckedKeys());
            // },
            // setCheckedNodes() {
            //   this.$refs.tree.setCheckedNodes([{
            //     id: 5,
            //     label: '二级 2-1'
            //   }, {
            //     id: 9,
            //     label: '三级 1-1-1'
            //   }]);
            // },
            // setCheckedKeys() {
            //   this.$refs.tree.setCheckedKeys([3]);
            // },
            // resetChecked() {
            //   this.$refs.tree.setCheckedKeys([]);
            // }




        }
    }
</script>



<style scoped>
    .boxThree {
        /*    border: 1px red dotted;
    box-sizing: content-box; */
        height: 400px;
        overflow: scroll;

    }
</style>